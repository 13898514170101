<template>
	<div class="outer-wrapper">
		<div class="fixed-top outer-wrapper">
			<router-link :to="{ name: 'Tours Dates' }">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>

			<div class="trip-type-holder">
				<span class="title">Tour Search</span>
			</div>
		</div>

		<div class="inner-wrapper tours search">
			<locationDropdown 
				v-model="toursSearch.searchValues.keyword"
				class="tour"

				placeholder="Country, city, activity or tour"
				locationType="tours"
			/>
			<v-date-picker 
				class="datepicker"
				v-model="toursSearch.searchValues.dates"
				mode="date" 
				:is-range="true"
				color="customblue"
				:min-date="new Date()"
				@popoverWillShow="goToDates()"
			>
				<template v-slot="{ isDragging, inputValue, inputEvents, togglePopover }">
					<input
						class="input is-range"
						placeholder="Date"
						:class="{ 'is-selecting': isDragging }"
						:value="inputValue.start"
						@click="togglePopover()"
					/>
					<img src="@/assets/filters/arrow-right.png" class="arrow" />
					<input
						placeholder="Return date"
						class="input return is-range"
						:class="{ 'is-selecting': isDragging }"
						:value="inputValue.end"
						@click="togglePopover()"
					/>
				</template>
			</v-date-picker>

			<!-- <div class="qty-holder">
				<div class="qty-select">
					<label for="adults">
						Adult(s)
						<div class="years">&gt;12 years</div>
					</label>
					<input v-model="toursSearch.searchValues.qty.adults" type="number" min="0" max="10" id="adults" v-on:focus="(e) => {e.target.value = '';}" v-on:blur="(e) => {e.target.value = this.searchValues.qty.adults;}">
				</div>
		
				<div class="qty-select">
					<label for="children">
						Children
						<div class="years">2-11 years</div>
					</label>
					<input v-model="toursSearch.searchValues.qty.children" type="number" min="0" max="10" id="children" v-on:focus="(e) => {e.target.value = '';}" v-on:blur="(e) => {e.target.value = this.searchValues.qty.children;}">
				</div>
			</div> -->
			
			<button class="button" v-on:click="submit()" :class="{ disabled: !checkForm }">Search</button>
			<div style="color: red" v-for="(errors, i) in this.searchFormErrors.values()" :key="i">
				<div v-for="(error, k) in errors" :key="k">
					{{ error }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions, mapGetters } from 'vuex'
	import router from '@/router';
	import locationDropdown from '@/components/locationDropdown.vue';
	import helpers from '@/helpers/helpers.js';
	
	export default {
		name: 'toursSearch',
		data() {
			return {
				debounceTimeout: null,
			}
		},
		components: {
			locationDropdown,
		},
		computed: {
			...mapState({
				toursSearch: state => state.toursSearch,
			}),
			...mapGetters('toursSearch', ['formattedDates', 'monthsAsString', 'searchFormErrors']),
			checkForm() {
				if (!this.searchFormErrors.size > 0) {
					return true;
				}
				return false;
				e.preventDefault();
			},
		},
		methods: {
			...mapActions({
				mapQueryParams: 'toursSearch/mapQueryParams',
			}),
			submit() {
				if(this.checkForm) {
					router.push('/tours-results'); // Results are loaded based on state on this next page
				}
			},
			formatDate: (date) => helpers.formatDate(date),
			goToDates() {
				router.push({ name: 'Tours Dates' });
			},
		},
		created() {
			this.toursSearch.shouldSearch = true;

			this.mapQueryParams();
		}		
	}
</script>

<style scoped>
	.text-input {
		height: 55px;
		box-sizing: border-box;
		line-height: 55px;
		text-align: left;
		cursor: pointer;
		margin-bottom: 16px;
		padding-right: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.text-input .placeholder {
		color: #999;
	}
	.trip-type-holder {
		position: relative;
		text-align: center;
	}
	.datepicker {
		text-align: left;
	}
	.datepicker input.is-range {
		width: calc(50% - 16px);
	}
	.datepicker input.is-range.return {
		float: right;
	}
	.datepicker .arrow {
		width: 12px;
		margin-left: 10px;
	}
	.vs__search {
		color: grey;
	}
	.title {
		width: 125px;
		display: inline-block;
		color: #118AB2;
		font-weight: bold;
		font-size: 20px;
	}
	.search-summary {
		color: #118AB2;
		position: relative;
	}
	.qty-holder {
		color: #50555C;
	}
	.qty-holder .qty-select {
		display: inline-block;
		margin: 0 20px;
	}
	.qty-holder .qty-select label {
		font-weight: bold;
		font-size: 14px;
	}
	.qty-holder .qty-select .years {
		font-size: 12px;
		font-weight: normal;
	}
	.qty-holder .qty-select input {
		width: 55px;
		height: 55px;
		box-sizing: border-box;
		border-radius: 10px;
		border: 1px solid rgba(60,60,60,.26);
		text-align: center;
		font-size: 16px;
		font-weight: bold;
		color: #50555C;
		margin-top: 12px;
		margin-bottom: 16px;
	}

	@media (min-width: 900px) {
		.qty-holder {
			margin-top: 10px;
		}
	}
	
</style>